<template>
  <transition name="slide">
    <CCard>
      <CCardHeader>
        <div
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <h5>
            <b>Booking confirmation</b>
          </h5>
          <!-- <CButton color="primary" @click="save">Update template</CButton> -->
        </div>
      </CCardHeader>
      <CCardBody>
        <CSpinner
          v-if="isLoading"
          style="margin-left: 50%; z-index: 999999999"
          color="primary"
        />
        <div v-else>
          <CAlert :color="alertType" v-if="message"> {{ message }}</CAlert>
          <CRow>
            <CCol col="12">
              <label style="width: 100%; font-weight: bold"
                >Body header
                <a
                  @click="
                    toggleContentTranslations = !toggleContentTranslations
                  "
                  ><CIcon
                    name="cil-globe-alt"
                    style=""
                    class="text-primary"
                  ></CIcon
                ></a>
              </label>
              <br />
              <br />
              <div
                class="input-group"
                style=""
                v-show="!toggleContentTranslations"
                v-if="template.headerTranslations[0]"
              >
                <div class="input-group-prepend">
                  <span
                    v-text="template.headerTranslations[0].languageCode"
                    class="input-group-text"
                  />
                </div>
                <CCol sm="12" style="padding: 0px">
                  <CTextarea
                    label=""
                    rows="3"
                    @id="template.headerTranslations[0].languageCode"
                    v-model="template.headerTranslations[0].content"
                  />
                </CCol>
              </div>
              <div
                v-for="(translation, k) in template.headerTranslations"
                :key="k"
                v-show="toggleContentTranslations"
              >
                <div class="input-group" style="padding-bottom: 5px">
                  <div class="input-group-prepend">
                    <span
                      v-text="translation.languageCode"
                      class="input-group-text"
                    />
                  </div>
                  <CCol sm="12" style="padding: 0px">
                    <CTextarea
                      label=""
                      rows="3"
                      @id="translation.languageCode"
                      v-model="template.headerTranslations[k].content"
                    />
                  </CCol>
                </div>
              </div>
            </CCol>
            <CCol col="12">
              <label style="width: 100%; font-weight: bold"
                >Body footer
                <a
                  @click="
                    toggleContentTranslations = !toggleContentTranslations
                  "
                  ><CIcon
                    name="cil-globe-alt"
                    style=""
                    class="text-primary"
                  ></CIcon
                ></a>
              </label>
              <br />
              <br />
              <div
                class="input-group"
                style=""
                v-show="!toggleContentTranslations"
                v-if="template.footerTranslations[0]"
              >
                <div class="input-group-prepend">
                  <span
                    v-text="template.footerTranslations[0].languageCode"
                    class="input-group-text"
                  />
                </div>
                <CCol sm="12" style="padding: 0px">
                  <CTextarea
                    label=""
                    rows="3"
                    @id="template.footerTranslations[0].languageCode"
                    v-model="template.footerTranslations[0].content"
                  />
                </CCol>
              </div>
              <div
                v-for="(translation, k) in template.footerTranslations"
                :key="k"
                v-show="toggleContentTranslations"
              >
                <div class="input-group" style="padding-bottom: 5px">
                  <div class="input-group-prepend">
                    <span
                      v-text="translation.languageCode"
                      class="input-group-text"
                    />
                  </div>
                  <CCol sm="12" style="padding: 0px">
                    <CTextarea
                      label=""
                      rows="3"
                      @id="translation.languageCode"
                      v-model="template.footerTranslations[k].content"
                    />
                  </CCol>
                </div>
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol col="8"></CCol>
            <CCol class="d-flex justify-content-end" style="margin-top: 20px">
              <CButton
                color="secondary"
                class="btn-lg"
                @click="goBack"
                style="margin-right: 15px"
                ><CIcon name="cil-list" /> Back to the list</CButton
              >
              <CButton @click="save" color="primary"
                ><CIcon name="cil-save" /> Save</CButton
              >
            </CCol>
          </CRow>
        </div>
      </CCardBody>
    </CCard>
  </transition>
</template>

<script>
import axios from "axios";

export default {
  name: "Templates",
  data: () => {
    return {
      token: localStorage.getItem("api_token"),
      partnerId: localStorage.getItem("partner_id"),
      emptyGuid: "00000000-0000-0000-0000-000000000000",
      toggleContentTranslations: false,
      isLoading: false,
      languages: [],
      template: {
        id: "00000000-0000-0000-0000-000000000000",
        partnerName: null,
        headerTranslations: [],
        footerTranslations: [],
      },
      // Alert
      message: null,
      alertType: "danger",
    };
  },
  computed: {},
  methods: {
    getList() {
      let self = this;
      self.isLoading = true;
      axios
        .get(this.$apiAdress + `/v1/Template`)
        .then(function (response) {
          if (response.data && response.data.length > 0) {
            self.template = response.data[0];
            self.isLoading = false;
          } else {
            self.getLanguages();
          }
        })
        .catch(function (error) {
          self.alertType = "danger";
          setTimeout(function () {
            self.message = null;
          }, 10000);
          self.message = error;
          self.isLoading = false;
        });
    },
    save() {
      let self = this;
      axios
        .post(`${this.$apiAdress}/v1/Template`, self.template)
        .then((response) => {
          self.template.id = response.data;
          self.alertType = "success";
          setTimeout(function () {
            self.message = null;
          }, 5000);
          self.message = "Successfully updated template.";
        })
        .catch(function (error) {
          self.alertType = "danger";
          setTimeout(function () {
            self.message = null;
          }, 10000);
          self.message = error.response.data.message;
        });
    },
    goBack() {
      this.$router.go(-1);
    },
    getLanguages() {
      let self = this;
      axios
        .get(`${this.$apiAdress}/v1/PartnerLanguage`)
        .then(function (response) {
          self.languages = response.data;
          if (self.template.id == self.emptyGuid) {
            for (let i = 0; i < self.languages.length; i++) {
              self.template.headerTranslations.push({
                languageCode: self.languages[i].languageCode,
                name: null,
                languageId: self.languages[i].languageId,
              });
              self.template.footerTranslations.push({
                languageCode: self.languages[i].languageCode,
                name: null,
                languageId: self.languages[i].languageId,
              });
            }
          }
          self.isLoading = false;
        })
        .catch(function (error) {
          self.alertType = "danger";
          setTimeout(function () {
            self.message = null;
          }, 10000);
          self.message = error;
          self.isLoading = false;
        });
    },
  },
  mounted: function () {
    this.getList();
  },
};
</script>

<style scoped>
.card-body >>> table > tbody > tr > td {
  cursor: pointer;
}
</style>
